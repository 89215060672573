import moment from 'moment'
import { handleQueryResolve } from '../utils'

export default function (fromDate, toDate) {
  // console.log(fromDate + ' ' + toDate);

  const dateFormat = 'DD/MM/YYYY'
  const fromDateStr = moment(fromDate).format(dateFormat)
  const toDateStr = moment(toDate).format(dateFormat)

  // const fromDate = '03/12/2019';
  // const toDate = '04/12/2019';

  // Fixme: For some reason this doesn't seem to work and give date conversion error
  // Need to figure out how to make this work instead of inserting string value
  // directly into query
  const attributes = []
  const where = []
  if (fromDate) {
    attributes.push({
      param: 'fromDate',
      type: 'sql.VarChar(60)',
      value: 'CONVERT(DATE,\'20191203\',112)',
    })

    attributes.push({
      param: 'toDate',
      type: 'sql.VarChar(60)',
      value: 'CONVERT(DATE,\'20191204\',112)',
    })

    where.push('CONVERT(DATE,Rx.RxDate) BETWEEN @fromDate')
    where.push('@toDate')
  }

  const queryStr = `
  SELECT TOP 150
  Rx.RxNum AS "RX NUMBER",
  Rx.FillDate  AS "RX DATE",
  Rx.Status AS STATUS,
  Rx.DIN,
  DrugRoot.GenericName AS "GENERIC NAME",
  DrugRoot.Strength  AS STRENGTH,
  DrugRoot.BrandName AS "BRAND NAME",
  Rx.DispQty AS "QTY DISPENSE",
  Rx.RemQty AS QtyRemain,
  Rx.SIG AS SIG,
  Rx.PatID AS "PATIENT ID",
  Rx.DocID AS "DOCTOR ID",
  rx.DaysSupply AS DAYS,
  Doc.LastName AS "DOCTOR LAST NAME",
  Doc.FirstName AS "DOCTOR FIRST NAME",
  Pat.FirstName AS "PATIENT FIRST NAME",
  Pat.LastName AS "PATIENT LAST NAME"
  FROM
  Pharmacy.dbo.Rx
INNER JOIN
  Pharmacy.dbo.Drg as DrugRoot ON Rx.DrgID = DrugRoot.ID
INNER JOIN
  Pharmacy.dbo.Pat ON Pat.ID = Rx.PatID
INNER JOIN
  Pharmacy.dbo.Doc ON Rx.DocID = Doc.ID
  WHERE
      Rx.FillDate  > DATEADD(day, -1, GetDate())
  ORDER BY Rx.FillDate Desc
      `

  // console.log(queryStr);
  return this.query(queryStr, attributes).then(handleQueryResolve)
}

// old query
// SELECT
//         Rx.RxNum AS "RX NUMBER",
//         Rx.FillDate  AS "RX DATE",
//         Rx.Status AS STATUS,
//         Rx.DIN,
//         DrugRoot.GenericName AS "GENERIC NAME",
//         DrugRoot.Strength  AS STRENGTH,
//         DrugRoot.BrandName AS "BRAND NAME",
//         Rx.DispQty AS "Qty Dispense",
//         Rx.RemQty AS QtyRemain,
//         Rx.SIG AS SIG,
//         Rx.PatID AS "PATIENT ID",
//         Rx.DocID AS "DOCTOR ID",
//         rx.DaysSupply AS DAYS,
//         Doc.LastName AS "DOCTOR LAST NAME",
//         Doc.FirstName AS "DOCTOR FIRST NAME",
//         DoctorFax.Phone as "DOCTOR FAX",
//         DoctorPhone.Phone as "DOCTOR PHONE",
//         Pat.FirstName AS "PATIENT FIRST NAME",
//         Pat.LastName AS "PATIENT LAST NAME"
//         FROM
//         Pharmacy.dbo.Rx
//     INNER JOIN
//         Pharmacy.dbo.Drg as DrugRoot ON Rx.DrgID = DrugRoot.ID
//     INNER JOIN
//         Pharmacy.dbo.Doc ON Rx.DocID = Doc.ID
//     LEFT JOIN
//         Pharmacy.dbo.DocPhone as DoctorFax ON Doc.ID = DoctorFax.DocID AND DoctorFax.Type = '2'
//     LEFT JOIN
//         Pharmacy.dbo.DocPhone as DoctorPhone ON Doc.ID = DoctorPhone.DocID AND DoctorPhone.Type = '1'
//         WHERE
//             Rx.FillDate
//                 BETWEEN CONVERT(DATE, '${fromDateStr}',103) AND CONVERT(DATE, '${toDateStr}',103)
//         ORDER BY Rx.FillDate Desc

// removed doctor details
// SELECT TOP 100
// Rx.RxNum AS "RX NUMBER",
// Rx.FillDate  AS "RX DATE",
// Rx.Status AS STATUS,
// Rx.DIN,
// DrugRoot.GenericName AS "GENERIC NAME",
// DrugRoot.Strength  AS STRENGTH,
// DrugRoot.BrandName AS "BRAND NAME",
// Rx.DispQty AS "QTY DISPENSE",
// Rx.RemQty AS QtyRemain,
// Rx.SIG AS SIG,
// Rx.PatID AS "PATIENT ID",
// Rx.DocID AS "DOCTOR ID",
// rx.DaysSupply AS DAYS,
// Doc.LastName AS "DOCTOR LAST NAME",
// Doc.FirstName AS "DOCTOR FIRST NAME",
// DoctorFax.Phone as "DOCTOR FAX",
// DoctorPhone.Phone as "DOCTOR PHONE",
// Pat.FirstName AS "PATIENT FIRST NAME",
// Pat.LastName AS "PATIENT LAST NAME"
// FROM
// Pharmacy.dbo.Rx
// INNER JOIN
// Pharmacy.dbo.Drg as DrugRoot ON Rx.DrgID = DrugRoot.ID
// INNER JOIN
// Pharmacy.dbo.Pat ON Pat.ID = Rx.PatID
// INNER JOIN
// Pharmacy.dbo.Doc ON Rx.DocID = Doc.ID
// LEFT JOIN
// Pharmacy.dbo.DocPhone as DoctorFax ON Doc.ID = DoctorFax.DocID AND DoctorFax.Type = '2'
// LEFT JOIN
// Pharmacy.dbo.DocPhone as DoctorPhone ON Doc.ID = DoctorPhone.DocID AND DoctorPhone.Type = '1'
// WHERE
//     Rx.FillDate  > DATEADD(day, -1, GetDate())
// ORDER BY Rx.FillDate Desc
